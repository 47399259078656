import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import banner from '../assets/images/Home/HomeBanner.webp'
import banner_mb from '../assets/images/Home/MobileBannerImg.png'
import Icon1 from '../assets/images/Home/Icon1.webp'
import Icon2 from '../assets/images/Home/Icon2.webp'
import Icon3 from '../assets/images/Home/Icon3.webp'
import SlickSlider2 from '../components/SlickSlider2'
import NelsonMandela from '../assets/images/Home/NelsonMandela.png'
import MuhammadAliJinnah from '../assets/images/Home/MuhammadAliJinnah.png'
import OurValuesWheel from '../assets/images/Home/wheel.webp'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'
import { Quote } from 'react-bootstrap-icons'
import HomeSlickSlider1 from '../components/HomeSlickSlider1'

const Home = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: true,
        arrows : false

      };
      window.scrollTo(0, 0);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <React.Fragment>
            <Helmet>
                <title> First Pakistan Global </title>
            </Helmet>
            <Header />
            <section id="website-responsive" className="">
                <div className="slider-wrapper text-lg-start text-center">
                    <Slider {...settings}>
                        <div className="slick-slide" key="1">
                            <img className="slick-slide-image d-none d-sm-none d-md-block" alt='Home Banner' src={banner} style={{width:"100%"}}/>
                            <img className="slick-slide-image d-md-none d-sm-block" alt='Home Banner' src={banner_mb} style={{width:"100%"}}/>
                            <div className='slick-slide-title'>
                            <div className='fw-700 text-uppercase banner-sub-title banner-position'>
                                <div className="banner-title fw-800">
                                First <br className='d-none d-md-block' />
                                <span className='text-ss-primary'> Pakistan </span> <br />
                                 Global
                                </div>
                            </div>
                            </div>
                        </div>
                    </Slider>
                </div>

        <div id='aboutfpg' className='container-fluid'>
                    <div className='row justify-content-between'>
                        <div className="d-flex col-12 col-md-5 bg-ourmission">
                        <div className="text-white fs-ss-22 col-12 my-auto p-md-5 px-3 py-5 fw-500 lh-lg">
                        <div className="fs-ss-22 p-md-5 fw-600">
                        Our mission is to support democracy, rule of law and civil rights in Pakistan by creating awareness through advocacy and educating the U.S. policymakers.
                        </div>
                        </div>   
                        </div>
                        <div className="col-11 col-md-7 py-5 my-auto">
                        <div className="col-11 col-md-8 mx-auto">
                        <h3 className='text-dark fs-ss-35 fw-600 pb-2 pb-md-4'>
                        What We <span className='text-ss-primary'> Stand </span> For
                    </h3>
            <div className='text-poppins fs-ss-16 lh-lg'>
            We stand for democracy, rule of law, freedom of speech, and an independent judiciary in Pakistan that will provide equal justice to all. First Pakistan Global, as a grassroots organization, creates public awareness and educates U.S. policymakers to support democratic change in Pakistan.
            </div>
            </div>
            </div>
                </div>
                    </div>

                    {/* <section className="py-md-5" />
                    
                    <div id='initiatives' className=''>
                    <section className='col-11 px-lg-4 mx-auto text-start'>
                    <h3 className='text-center text-dark fs-ss-35 fw-600 pb-4'>
                        Upcoming <span className='text-ss-primary'> Events </span>
                    </h3>
                    <div className='px-2'>
            <div className='row justify-content-evenly gy-5 text-center '>
                <div className="col-11 col-md-9 shadow-ss-1 bg-grey px-3 py-5 p-md-5 rounded-3 border-bottom-green">
                    <div className="fs-ss-24 fw-600 lh-sm">
                        Congressional Briefing on Pakistan’s Human Rights Crisis
                    </div>
                    <div className="fs-ss-16 fw-400 mt-1 fw-500">
                    House of Representative, Washington D.C.
                    </div>
                    <div id='btn-events' className="mt-4 fw-500">
                        <span className='bg-ss-primary me-md-3 text-white rounded-5 px-4 py-1 fs-ss-16 mb-2 mb-md-4'> Date: Wednesday, January 22, 2025 </span>
                        <div className="py-1 d-block d-md-none" />                      
                        <span className='bg-ss-primary text-white rounded-5 px-4 py-1 fs-ss-16'> Time: 2:30 pm – 3:30 pm (EST) </span>
                    </div>
                    <div className="mt-4 mt-md-3 fw-500">
                        <span className='d-block d-md-inline'> Invitation-only Event </span> <span className='d-none d-md-inline'> | </span>
                        <span className='d-block d-md-inline'> RSVP: info@firstpakistanglobal.org </span>
                    </div>
                </div>
            </div>
        </div>
                    </section>
                    </div> */}


                    <section className="py-md-5" />
                    
                    <div id='event-home' className=''>
                        <section className='col-11 px-lg-4 mx-auto text-start'>
                            <h3 className='text-center text-dark fs-ss-35 fw-600 pb-md-2'>
                                Congressional <span className='text-ss-primary'> Briefing </span>
                            </h3>
                            <div className='px-2 py-5'>
                                <div className='row justify-content-evenly gy-md-4 gy-2 px-3 py-4 text-center mx-md-5 shadow-ss-1 bg-grey rounded-3 border-bottom-green'>
                                    <div className="col-12 col-md-6 px-md-3 px-2 pb-4">
                                        {/* <iframe src="https://drive.google.com/file/d/18RY8BUtuZisMoZimYf_nsTqnP123MwTe/preview" width="100%" height="340" allow="autoplay"></iframe> */}
                                        <HomeSlickSlider1/>
                                    </div>

                                    <div className="col-12 col-md-6 px-lg-5 px-md-4 px-2 pt-lg-5 pt-2 pb-lg-5 pb-4 text-start">
                                        <div className="fs-ss-26 fw-600 lh-1-5">
                                            Islamabad Massacre & Human Rights Crisis in Pakistan
                                        </div>
                                        <div className="fs-ss-16 fw-400 mt-3 fw-500">
                                            On January 22, 2025, First Pakistan Global, Congressman Greg Casar's office, and coalition organizations hosted a pivotal Congressional.
                                        </div>
                                        {/* <div id='btn-events' className="mt-4 fw-500">
                                            <span className='bg-ss-primary me-md-3 text-white rounded-5 px-4 py-1 fs-ss-16 mb-2 mb-md-4'> Date: Wednesday, January 22, 2025 </span>
                                        </div> */}
                                        
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="py-md-4 py-2" />
                    
                    <div id='initiatives' className=''>
                    <section className='col-11 px-lg-4 mx-auto text-start'>
                    <h3 className='text-center text-dark fs-ss-35 fw-600 pb-4'>
                        Building a <span className='text-ss-primary'> Better </span> <br className='d-block d-md-none' /> Tomorrow, Together
                    </h3>
                    <div className='px-2'>
            <div className='row justify-content-evenly gy-5'>
                <div className="col-11">
                    <SlickSlider2/>
                </div>
            </div>
        </div>
                    </section>
                    </div>

                    <section className="py-4 py-md-5" />
                    
                    <div  id='core-values' className=''>
                    <section className='col-11 px-lg-4 mx-auto text-start'>
                    <div className='px-2'>
                    <h3 className='text-center text-dark fs-ss-35 fw-600 pb-4'>
                        Our Core <span className='text-ss-primary'> Values </span>
                    </h3>
            <div className='row justify-content-center gy-4 align-items-center'>
                <div className="col-md-3 pb-md-5 mb-md-5">
                <img src={OurValuesWheel} className='w-75 mx-auto px-4 d-block d-md-none pb-4' alt="" />
                <div className="fs-ss-22 fw-600 pb-1">
                    Democracy
                </div>
                <div className='text-poppins fs-ss-16 lg-lg mb-md-5'>
                Fostering democratic principles and practices to ensure freedom and fair representation for all Pakistanis.
            </div>
                </div>
                <div className="col-md-4">
                    <img src={OurValuesWheel} className='w-75 mx-auto px-4 d-none d-md-block' alt="" />
                    <div className="fs-ss-22 fw-600 pb-1 pt-md-4">
                        Human Rights
                    </div>                    
                    <div className='text-poppins fs-ss-16 lg-lg'>
                    Protecting and promoting the fundamental human rights of every Pakistani, regardless of where they are.
                    </div>
                </div>
                <div className="col-md-3 pb-md-5 mb-md-5">
                <div className="fs-ss-22 fw-600 pb-1">
                    Justice
                </div>
                <div className='text-poppins fs-ss-16 lg-lg mb-md-5'>
                Advocating for equitable treatment and justice, fighting against discrimination and injustice.
            </div>            
                </div>
            </div>
        </div>
                    </section>
                    </div>

                    <section className="py-3"/>
                    <section className="py-md-4 py-3" />

                    <div className='container-fluid bg-middle-banner py-5 px-3'>
                    <div className='py-md-5'>
                        <h3 className='text-white fs-ss-35 fw-600 text-center'>
                            What We Do 
                        </h3>
                        <div className='row justify-content-center gy-5 pt-5 ms-md-5 text-center text-md-start'>
                        <div className="col-md-3">
                    <img src={Icon1} className='w-icons' alt="" /> 
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3 text-white"> 
                    Advocacy
                    </div>   
                    <div className="fs-ss-14 pt-2 text-white text-md-start col-12 col-md-9 ">
                    We engage with global policymakers, governments, and organizations to represent Pakistanis' interests and advocate for democracy and human rights.
                    </div>                
                </div>
                <div className="col-md-3">
                    <img src={Icon3} className='w-icons' alt="" />
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3 text-white"> 
                    Education
                    </div>
                    <div className="fs-ss-14 pt-2 text-white text-md-start col-12 col-md-9 ">
                    Providing educational resources on accessing legal aid, crisis support, and community assistance for Pakistanis in need.
                    </div>    
                </div>
                <div className="col-md-3">
                    <img src={Icon2} className='w-icons' alt="" /> 
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3 text-white"> 
                    Community Building
                    </div>   
                    <div className="fs-ss-14 pt-2 text-white text-md-start col-12 col-md-9 ">
                    Connecting Pakistanis around the world   through events, forums, and networks   to foster a strong, united   global community.
                    </div>                 
                </div>
            </div>                        
            </div>
            </div>

            <section className="py-md-3"/>
            <section className="py-4" />       

            {/* <div id='contact-us' className='container-fluid'>
            <h3 className='text-center text-dark fs-ss-35 fw-600 pb-4'>
                News
                <hr className="hr" />
            </h3>
            <div className='row justify-content-center gy-5 px-3'>
            <div className="col-md-3 ms-md-5">
                    <img src={Img7} className='w-100 shadow-ss-1' alt="" />
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3"> 
                        Be a fund raiser
                    </div>
                    <div className="fs-ss-14 pt-2 lh-sm">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et quisquam deleniti culpa numquam quos maxime.
                    </div>
                </div>
                <div className="col-md-3 ms-md-5">
                    <img src={Img7} className='w-100 shadow-ss-1' alt="" />
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3"> 
                        Join Us
                    </div>
                    <div className="fs-ss-14 pt-2 lh-sm">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et quisquam deleniti culpa numquam quos maxime.
                    </div>
                </div>
                <div className="col-md-3">
                    <img src={Img6} className='w-100 shadow-ss-1' alt="" /> 
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3"> 
                        Attend an event
                    </div>   
                    <div className="fs-ss-14 pt-2 lh-sm">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et quisquam deleniti culpa numquam quos maxime.
                    </div>                
                </div>
            </div>
            </div>

        <section className="py-md-3"/>
        <section className="py-4 py-md-5" /> */}
        
        <div id='website-responsive' className='container-fluid'>
            <div id='legends' className="row justify-content-center gap-4">
            <div className="col-10 col-md-5 border-green p-2 p-md-4 d-block d-md-none">
            <div className="d-md-flex align-items-center">
                    <div className="col-md-3">
                        <img src={MuhammadAliJinnah} className='w-100' alt="" />
                        </div>
                        <div className="px-4 my-4 my-md-0">
                            <div className="fs-ss-30 text-center text-monotypecorsiva lh-sm">
                            With faith, discipline and selfless devotion to duty, there is nothing worthwhile that you cannot achieve.
                            </div>
                            </div>
                            </div>
                            <div className="text-end fs-ss-20 fw-700 pt-2">
                            Muhammad Ali Jinnah
                            </div>
                            </div>   
            <div className="col-10 col-md-5 border-green p-2 p-md-4">
            <div className="d-md-flex align-items-center">
                    <div className="col-md-3">
                        <img src={NelsonMandela} className='w-100' alt="" />
                        </div>
                        <div className="px-4 my-4 my-md-0">
                            <div className="fs-ss-30 text-center text-monotypecorsiva lh-sm">
                                To deny people their human rights is to challenge their very humanity.
                            </div>
                            </div>
                            </div>
                            <div className="text-end fs-ss-20 fw-700 pt-2">
                                Nelson Mandela
                            </div>
                            </div>

            <div className="col-10 col-md-5 border-green p-2 p-md-4 d-none d-md-block">
            <div className="d-md-flex align-items-center">
                    <div className="col-md-3">
                        <img src={MuhammadAliJinnah} className='w-100' alt="" />
                        </div>
                        <div className="px-4 my-4 my-md-0">
                            <div className="fs-ss-30 text-center text-monotypecorsiva lh-sm">
                            With faith, discipline and selfless devotion to duty, there is nothing worthwhile that you cannot achieve.
                            </div>
                            </div>
                            </div>
                            <div className="text-end fs-ss-20 fw-700 pt-2">
                            Muhammad Ali Jinnah
                            </div>
                            </div>                            
                            </div>
                            </div>
                            <section className="py-md-3"/>
                            <section className="py-4" />      

        {/* <div id='contact-us' className='container-fluid'>
            <div className='row justify-content-center gy-5 px-3'>
                <div className="col-md-3 align-self-center text-center text-md-start">
                    <div className="fs-ss-40 text-uppercase fw-700 pt-3 lh-sm"> 
                        How can you <br className="" />
                        <span className='fs-ss-68 text-ss-primary fw-800'> Help </span>
                    </div>
                    <div className="pt-4">
                    <a href="tel:0000000000" className="text-uppercase py-2 fs-ss-15 text-poppins btn-ss-primary2 rounded-5 btn px-4 fw-600">
                    Contact with us
                    </a>
                    </div>
                </div>
                <div className="col-md-3 ms-md-5">
                    <img src={Img7} className='w-100' alt="" />
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3"> 
                    <a href="" className='hover-ss-primary text-decoration-none'>  Be a fund raiser </a>
                    </div>
                    <div className="fs-ss-14 pt-2 lh-sm">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et quisquam deleniti culpa numquam quos maxime.
                    </div>
                </div>
                <div className="col-md-3">
                    <img src={Img6} className='w-100' alt="" /> 
                    <div className="fs-ss-18 text-uppercase fw-600 pt-3"> 
                    <a href="" className='hover-ss-primary text-decoration-none'>  Attend an event
                        
                    </a>
                    </div>   
                    <div className="fs-ss-14 pt-2 lh-sm">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et quisquam deleniti culpa numquam quos maxime.
                    </div>                
                </div>
            </div>
        </div>
                    
                    <section className="py-md-3"/>
                    <section className="py-4" />                         */}
        </section>                         
            <Footer />
        </React.Fragment>
    )
}

export default Home
